import * as React from 'react';
import { CacheProvider, type EmotionCache } from '@emotion/react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AppStateWrapper } from '@src/utility/state';
import { type NextComponentType, type NextPageContext } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { SessionProvider, useSession } from 'next-auth/react';

import createEmotionCache from '../src/createEmotionCache';
import theme from '../src/theme';

import '../styles/style.css';
import '../styles/swagger-ui.css';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export interface MyAppProps extends AppProps {
  Component: NextComponentType<NextPageContext> & { auth: boolean };
  emotionCache?: EmotionCache;
}

function Auth({ children }: { children: JSX.Element }): JSX.Element {
  const { status } = useSession({
    required: true,
    onUnauthenticated: () => {
      // we can try to store this inside the internal state and use useRouter to redirect
      const url = '/login';
      window.location.href = url;
    },
  });

  if (status === 'loading') {
    return (
      <Box sx={{ display: 'flex', width: '100%', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return children;
}

export default function MyApp(props: MyAppProps): JSX.Element {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps: { session, ...pageProps },
  } = props;
  return (
    <SessionProvider session={session}>
      <CacheProvider value={emotionCache}>
        <Head>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Head>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <AppStateWrapper>
              {!Component.auth ? (
                <Component {...pageProps} />
              ) : (
                <Auth>
                  <Component {...pageProps} />
                </Auth>
              )}
            </AppStateWrapper>
          </LocalizationProvider>
        </ThemeProvider>
      </CacheProvider>
    </SessionProvider>
  );
}

MyApp.defaultProps = {
  emotionCache: clientSideEmotionCache,
};
