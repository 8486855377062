import React from 'react';
import { isNullish, isServer } from '@src/util';

export const STORAGE_KEY = 'dashStorage';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ActionMap<M extends Record<string, any>> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum ActionTypes {
  SetEmail = 'setEmail',
  RemoveEmail = 'removeEmail',
  SetShowSubskriptSignupWindow = 'setShowSubskriptSignupWindow',
  SetPage = 'setPage',
  RemovePage = 'removePage',
  SetABN = 'setABN',
  RemoveABN = 'removeABN',
  SetEntityName = 'setEntityName',
  RemoveEntityName = 'removeEntityName',
  SetAcceptTerms = 'setAcceptTerms',
  RemoveAcceptTerms = 'removeAcceptTerms',
  SetPlan = 'setPlan',
  RemovePlan = 'removePlan',
  SetBankAuthorityEmail = 'setBankAuthorityEmail',
  RemoveBankAuthorityEmail = 'removeBankAuthorityEmail',
  SetBankAuthorityPhone = 'setBankAuthorityPhone',
  RemoveBankAuthorityPhone = 'removeBankAuthorityPhone',
  SetTechnicalEmail = 'setTechnicalEmail',
  RemoveTechnicalEmail = 'removeTechnicalEmail',
  SetTechnicalPhone = 'setTechnicalPhone',
  RemoveTechnicalPhone = 'removeTechnicalPhone',
  setTestMode = 'setTestMode',
}

export interface StateType {
  email?: string;
  showSubskriptSignupWindow?: boolean;
  page?: string;
  abn?: string;
  entityName?: string;
  acceptTerms?: boolean;
  plan?: string;
  bankAuthorityEmail?: string;
  bankAuthorityPhone?: string;
  technicalEmail?: string;
  technicalPhone?: string;
  testMode?: boolean;
}

interface StateActionPayload {
  [ActionTypes.SetEmail]: {
    email: string;
  };
  [ActionTypes.RemoveEmail]: undefined;
  [ActionTypes.SetShowSubskriptSignupWindow]: {
    showSubskriptSignupWindow: boolean;
  };
  [ActionTypes.SetPage]: {
    page: string;
  };
  [ActionTypes.RemovePage]: undefined;
  [ActionTypes.SetABN]: {
    abn?: string;
  };
  [ActionTypes.RemoveABN]: undefined;
  [ActionTypes.SetEntityName]: {
    entityName?: string;
  };
  [ActionTypes.RemoveEntityName]: undefined;
  [ActionTypes.SetAcceptTerms]: {
    acceptTerms?: boolean;
  };
  [ActionTypes.RemoveAcceptTerms]: undefined;
  [ActionTypes.SetPlan]: {
    plan?: string;
  };
  [ActionTypes.RemovePlan]: undefined;
  [ActionTypes.SetBankAuthorityEmail]: {
    bankAuthorityEmail: string;
  };
  [ActionTypes.RemoveBankAuthorityEmail]: undefined;
  [ActionTypes.SetBankAuthorityPhone]: {
    bankAuthorityPhone: string;
  };
  [ActionTypes.RemoveBankAuthorityPhone]: undefined;
  [ActionTypes.SetTechnicalEmail]: {
    technicalEmail: string;
  };
  [ActionTypes.RemoveTechnicalEmail]: undefined;
  [ActionTypes.SetTechnicalPhone]: {
    technicalPhone: string;
  };
  [ActionTypes.RemoveTechnicalPhone]: undefined;
  [ActionTypes.setTestMode]: {
    testMode: boolean;
  };
}

export type StateAction = ActionMap<StateActionPayload>[keyof ActionMap<StateActionPayload>];

const init = { testMode: true };

function resolvePersisted() {
  if (isServer()) {
    return null;
  }
  if (!isNullish(window.sessionStorage.getItem(STORAGE_KEY))) {
    return JSON.parse(window.sessionStorage.getItem(STORAGE_KEY) as string) as StateType;
  }
  return null;
}

const persisted = resolvePersisted();

const initalState: StateType = {
  ...init,
  ...persisted,
};

const AppContext = React.createContext<{
  state: StateType;
  dispatch: React.Dispatch<StateAction>;
}>({
  state: initalState,
  dispatch: () => null,
});

function appReducer(prevState: StateType, action: StateAction): StateType {
  switch (action.type) {
    case ActionTypes.SetEmail: {
      return { ...prevState, email: action.payload.email };
    }
    case ActionTypes.RemoveEmail: {
      const { email, ...newState } = prevState;
      return newState;
    }
    case ActionTypes.SetShowSubskriptSignupWindow: {
      return { ...prevState, showSubskriptSignupWindow: action.payload.showSubskriptSignupWindow };
    }
    case ActionTypes.SetPage: {
      return { ...prevState, page: action.payload.page };
    }
    case ActionTypes.RemovePage: {
      const { page, ...newState } = prevState;
      return newState;
    }
    case ActionTypes.SetABN: {
      return { ...prevState, abn: action.payload.abn };
    }
    case ActionTypes.RemoveABN: {
      const { abn, ...newState } = prevState;
      return newState;
    }
    case ActionTypes.SetEntityName: {
      return { ...prevState, entityName: action.payload.entityName };
    }
    case ActionTypes.RemoveEntityName: {
      const { entityName, ...newState } = prevState;
      return newState;
    }
    case ActionTypes.SetAcceptTerms: {
      return { ...prevState, acceptTerms: action.payload.acceptTerms };
    }
    case ActionTypes.RemoveAcceptTerms: {
      const { acceptTerms, ...newState } = prevState;
      return newState;
    }
    case ActionTypes.SetPlan: {
      return { ...prevState, plan: action.payload.plan };
    }
    case ActionTypes.RemovePlan: {
      const { plan, ...newState } = prevState;
      return newState;
    }
    case ActionTypes.SetBankAuthorityEmail: {
      return { ...prevState, bankAuthorityEmail: action.payload.bankAuthorityEmail };
    }
    case ActionTypes.RemoveBankAuthorityEmail: {
      const { bankAuthorityEmail, ...newState } = prevState;
      return newState;
    }
    case ActionTypes.SetBankAuthorityPhone: {
      return { ...prevState, bankAuthorityPhone: action.payload.bankAuthorityPhone };
    }
    case ActionTypes.RemoveBankAuthorityPhone: {
      const { bankAuthorityPhone, ...newState } = prevState;
      return newState;
    }
    case ActionTypes.SetTechnicalEmail: {
      return { ...prevState, technicalEmail: action.payload.technicalEmail };
    }
    case ActionTypes.RemoveTechnicalEmail: {
      const { technicalEmail, ...newState } = prevState;
      return newState;
    }
    case ActionTypes.SetTechnicalPhone: {
      return { ...prevState, technicalPhone: action.payload.technicalPhone };
    }
    case ActionTypes.RemoveTechnicalPhone: {
      const { technicalPhone, ...newState } = prevState;
      return newState;
    }
    case ActionTypes.setTestMode: {
      return { ...prevState, testMode: action.payload.testMode };
    }
    default: {
      return prevState;
    }
  }
}

export function AppStateWrapper({ children }: { children: React.ReactNode }): JSX.Element {
  const [state, dispatch] = React.useReducer(appReducer, initalState);

  React.useEffect(() => {
    if (!isServer()) {
      window.sessionStorage.setItem(
        STORAGE_KEY,
        JSON.stringify({
          ...state,
        })
      );
    }
  }, [state]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
}

export function useStateContext(): {
  state: StateType;
  dispatch: React.Dispatch<StateAction>;
} {
  return React.useContext(AppContext);
}
