import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#06BDFF',
      dark: '#008fc3',
      light: '#EDF5FF',
      // hover: '#cee4ff',
    },
    secondary: {
      main: '#172B4D',
      light: '#F8F9FA',
    },
    text: {
      primary: '#21282E',
      disabled: '#DEE2E6',
    },
    error: {
      main: '#E4002B',
      light: '#FFE7D9',
      dark: '#C92A2A',
    },
    warning: {
      main: '#F08C00',
      light: '#FFF3CC',
      dark: '#B14E06',
    },
    success: {
      main: '#29CC6A',
      light: '#C9F4E2',
      dark: '#1D6226',
    },
    info: {
      main: '#0099FF',
      light: '#F3F0FF',
      // dark: '#7048E8',
    },
    /*
    skript: {
      main: '#06BDFF',
      dark: '#008fc3',
      light: '#EDF5FF',
      hover: '#cee4ff',
    }, */
    grey: {
      50: '#F1F3F5',
      100: '#F8F9FA',
      200: '#E9ECEF',
      300: '#D9D9D9',
      400: '#CFD4DA',
      500: '#ADB5BD',
      600: '#868E96',
      800: '#495057',
      900: '#343A40',
    },
  },
  shape: {
    borderRadius: 3,
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    h1: {
      fontSize: '32px',
      fontWeight: '600',
      fontFamily: 'Inter, sans-serif',
    },
    h2: {
      fontSize: '25px',
      fontWeight: '600',
      fontFamily: 'Inter, sans-serif',
      letterSpacing: '-0.36px',
      color: '#172B4D',
    },
    h3: {
      fontSize: '20px',
      fontWeight: '600',
      fontFamily: 'Inter, sans-serif',
    },
    h4: {
      fontSize: '18px',
      fontWeight: '600',
      fontFamily: 'Inter, sans-serif',
    },
    h5: {
      fontSize: '16px',
      fontWeight: '600',
      fontFamily: 'Inter, sans-serif',
    },
    h6: {
      fontSize: '14px',
      fontWeight: '600',
      fontFamily: 'Inter, sans-serif',
    },
    subtitle1: {
      fontSize: '18px',
      fontWeight: '600',
      fontFamily: 'Inter, sans-serif',
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: '400',
      fontFamily: 'Inter, sans-serif',
    },
    body1: {
      fontSize: '16px',
      letterSpacing: '-0.42px',
      fontWeight: '400',
      fontFamily: 'Inter, sans-serif',
      lineHeight: '20px',
    },
    body2: {
      fontSize: '14px',
      letterSpacing: '-0.42px',
      fontWeight: '400',
      fontFamily: 'Inter, sans-serif',
      lineHeight: '18px',
    },
    button: {
      fontSize: '16px',
      fontWeight: '600',
      textTransform: 'initial',
      fontFamily: 'Inter, sans-serif',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
      variants: [
        {
          props: { variant: 'text' },
          style: {
            fontWeight: '400',
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            backgroundColor: '#06BDFF',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#008fc3',
            },
          },
        },
        /*
        {
          props: { variant: 'secondary' },
          style: {
            backgroundColor: '#EDF5FF',
            color: '#06BDFF',
            '&:hover': {
              backgroundColor: '#cee4ff',
            },
          },
        },
        {
          props: { variant: 'success' },
          style: {
            backgroundColor: '#A9FFD4',
            color: '#29CC6A',
            '&:hover': {
              backgroundColor: '#76ffba',
            },
          },
        },
        */
        {
          props: { size: 'large' },
          style: {
            padding: '10px 30px',
            fontSize: '14px',
          },
        },
      ],
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#EDF5FF',
          boxShadow: '0px 4px 8px 0px rgba(0,0,0,0.05)',
          '&.MuiInputBase-root': {
            borderRadius: '8px',
          },
          '&.MuiInputBase-multiline': {
            '& .MuiOutlinedInput-input': {
              fontWeight: 400,
            },
          },
        },
        input: {
          borderRadius: '8px',
          border: 'none',
          color: '#495057',
          fontWeight: 400,
          fontSize: '15px',
          '&~.MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '&[readonly]': {
            fontWeight: 400,
            color: '#ADB5BD',
          },
          '&[disabled]': {
            color: '#ADB5BD',
          },
          '&::placeholder': {
            color: '#ADB5BD',
            opacity: 1,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.MuiInputLabel-outlined': {
            position: 'relative',
            transform: 'none',
            color: '#868E96',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '-0.42px',
            paddingBottom: '4px',
            paddingLeft: '10px',
            '&.Mui-focused': {
              color: '#06BDFF',
            },
          },
        },
      },
    },
    /*
    MuiSelect: {
      styleOverrides: {
        // root: {
          // borderRadius: 8,
          '& .MuiSelect-select.Mui-disabled': {
            backgroundColor: '#fff',
          },
        //},
        select: {
          borderRadius: 8,
        },
      },
    },
    button: {
      fontSize: '16px',
      fontWeight: '600',
      textTransform: 'initial',
      fontFamily: 'Inter, sans-serif',
    },
    */
  },
  /*
  MuiInputBase: {
    styleOverrides: {
      root: {
        borderRadius: 8,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: 8,
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: 8,
      },
    },
  },
    */
});

export default theme;
